<template>
  <div>
    <div class="flex flex-col w-full py-2 text-center">
      <h1 class="text-2xl font-bold text-gray-700 sm:text-3xl">
        Has olvidado tu contraseña
      </h1>
    </div>
    <div class="flex flex-col py-2 text-left">
      <el-form
        ref="forgotForm"
        :model="forgotForm"
        :rules="forgotRules"
        hide-required-asterisk>
        <el-form-item
          label="Correo Electrónico" prop="email" autocomplete="off">
          <el-input v-model="forgotForm.email" />
        </el-form-item>
      </el-form>
      <div class="w-full flex flex-row" style="justify-content: right;">
        <sa-button
          class="rounded text-bold mr-2"
          custom-class="w-full"
          type="secondary"
          @click="$router.push({ name: 'login.index' })">
          Cancelar
        </sa-button>
        <sa-button
          class="rounded text-bold"
          custom-class="w-full"
          type="primary"
          v-loading="loading"
          element-loading-background="#DDD6FE"
          element-loading-spinner=""
          @click="submit()">
          Enviar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<script>
import SignService from '@/services/SignService';
import error from '@/mixins/error';
import _ from 'lodash';

export default {
  name: 'ForgotForm',
  mixins: [error],
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      forgotForm: {
        email: null,
      },
      forgotRules: {
        email: [{ required: true, type: 'email', message: 'Captura tu email', trigger: 'change' }],
      },
    };
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    async submit() {
      this.$refs['forgotForm'].validate(async (isFormValid) => {
        if (!isFormValid) return;
        await this.forgot();
      });
    },
    async forgot() {
      this.loading = true;
      try {
        await SignService.forgot({ email: this.forgotForm.email.toLowerCase() });
        this.$swal("Todo Listo!", "Te hemos enviado un correo de confirmacion para reestablecer tu cuenta!", "success").then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'login.index' });
          }
        });
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
